<template>
  <section class="dashboard" v-if="$route.params && $route.params.accountId">
    <h1>
      Admin
    </h1>
    <p>View account: <span>{{ $route.params.accountId }}</span></p>

    <div class="grid">
      <article class="account-item" v-for="(item, i) in clientsList" :key="i">
        <img v-if="item.profileImg" :src="item.profileImg">
        <router-link :to="{ name: 'AdminViewAdz', params: { accountId: $route.params.accountId, clientId: item.id } }" ><h2>{{ item.name }}</h2></router-link>
        <p><strong>Nb campaigns:</strong> {{ item.nbCampaigns ? item.nbCampaigns : 0 }}</p>
        <p><strong>imageRef:</strong> {{ item.imageRef ? item.imageRef : '' }}</p>
        <p><strong>profileImgPath:</strong> {{ item.profileImg ? item.profileImg : '' }}</p>
      </article>
    </div>
  </section>
</template>

<script>
import { isNil } from 'lodash'
import { mapState } from 'vuex'
import AccountClientsDB from '@/firebase/account-clients-db'

export default {
  name: 'AdminViewAccount',

  head: function () {
    return {
      title: {
        inner: 'Admin View Account',
      },
      meta: [
        {
          name: 'description',
          content: `Admin account`,
          id: 'desc',
        },
      ],
    }
  },

  data() {
    return {
      isLoading: true,
      clientsList: [],
    }
  },

  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle']),
    ...mapState('authentication', ['user']),
    ...mapState('users', ['account', 'clients']),
  },

  watch: {
    user: {
      async handler(user) {
        if (!isNil(user)) {
          if (user.appRole !== 'admin') {
            this.$router.push('/')
          }

          const { accountId } = this.$route.params
          const accountClients = new AccountClientsDB(accountId)
          this.clientsList = await accountClients.readAll()

          this.isLoading = false
        }
      },
      immediate: true,
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dashboard {
  padding: 25px;
}

.grid {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 45px 0;

  .account-item {
    width: calc(98% / 3);
    padding: 20px;
    background: rgba(#000, 0.05123);
    border: 2px solid rgba(#000, 0.05123);
    border-radius: 20px;
    margin-bottom: 1%;
    text-align: center;

    color: #323232;

    img {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 60px;
      border-radius: 50%;
    }

    h2 {
      margin-bottom: 15px;
    }

    p {
      font-size: 12px;
      line-height: 2;
    }
  }
}
</style>
